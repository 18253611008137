import React from 'react'
import PropTypes from 'prop-types'
import {
  graphql
} from 'gatsby'
import Layout from '../components/Layout'
import Content, {
  HTMLContent
} from '../components/Content'


export const WinePageTemplate = () => {
  console.log(styles);
  return ( <
    div >
    <
    div className = "full-width-image-container margin-top-0"
    style = {
      {
        backgroundImage: `url('/img/beer-index.jpeg')`,
      }
    } >
    <
    h1 className = "has-text-weight-bold is-size-3-mobile is-size-2-tablet is-size-1-widescreen"
    style = {
      {
        color: 'white',
        lineHeight: '1',
        padding: '0.25em',
        fontFamily: 'Lulo'
      }
    } >
    New Arrivals <
    /h1> < /
    div > <
    section className = "section" >
    <
    div className = "row"
    id = "wine" >
    <
    div className = "column"
    id = "container" >
    <
    ul >
    <
    li style = {
      styles.wine
    } > Andechser Doppelbock Dunkel | Klosterbrauerei Andechs < /li> <
    li style = {
      styles.wine
    } > Earth Below | New Anthem Beer Project < /li> <
    li style = {
      styles.wine
    } > Forbidden Exchange | Heist Brewery < /li> <
    li style = {
      styles.wine
    } > Long Leaf IPA | Appalachian Mountain Brewery < /li> <
    li style = {
      styles.wine
    } > Mocha Java Porter | Sugar Creek < /li> <
    li style = {
      styles.wine
    } > HiWire Lager | HiWire Brewing < /li> <
    li style = {
      styles.wine
    } > Ordinary Unicorn | Full Steam < /li> <
    li style = {
      styles.wine
    } > Delirium Tremens | Huyghe Brewery < /li> <
    li style = {
      styles.wine
    } > * on draft < /li> < /
    ul > <
    /div> < /
    div > <
    /section>    < /
    div >

  )
}

const styles = {
  wine: {
    fontWeight: 200,
    fontFamily: "Lulo",
    textAlign: "center",
    fontSize: 25
  }
}

// WinePageTemplate.propTypes = {
//   title: PropTypes.string.isRequired,
//   content: PropTypes.string,
//   contentComponent: PropTypes.func,
// }

const WinePage = () => {
  return ( <
    Layout >
    <
    WinePageTemplate /
    >
    <
    /Layout>
  )
}


export default WinePage;
